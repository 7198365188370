<template>
  <div class="form-group">
    <label v-if="label" class="form-label">{{ label }}</label>

    <div class="input-wrap" :class="{ 'events-disable': loading }">
      <span class="btn p-0 rounded-left bg-light events-disable" v-if="icon"
        ><i :class="icon"></i
      ></span>

      <input
        class="form-input bg-lighter z-1"
        aria-label="input"
        :placeholder="placeholder"
        :type="type"
        :class="style"
        :max="max"
        :min="min"
        @click="$emit('click', $event)"
        @keyup="$emit('keyup', $event)"
        @keydown="$emit('keydown', $event)"
        @keypress="$emit('keypress', $event)"
        @change="
          $emit('change', $event);
          (min || max) && type === 'number' ? verifyNumber(_value) : {};
        "
        @blur="$emit('blur', $event)"
        @focus="$emit('focus', $event)"
        v-model="_value"
      />

      <span
        class="border-btn border-left-0 p-0"
        :class="btnStyle"
        v-if="loading"
        ><i class="i-spin"></i
      ></span>
      <span
        class="border-btn border-left-0 p-0"
        :class="btnStyle"
        @click="button.click"
        v-if="!loading && button"
      >
        <i v-if="button.icon" :class="button.icon"></i>
        {{ button.text }}
      </span>
    </div>

    <span v-if="help !== undefined" class="form-help">{{ help }}</span>
  </div>
</template>

<script>
export default {
  props: {
    placeholder: [String, Number],
    label: [String, Number],
    help: {
      type: [String, Number],
      default: undefined
    },
    type: {
      type: String,
      default: "text"
    },
    loading: {
      type: Boolean,
      default: false
    },
    valid: {
      type: Boolean,
      default: true
    },
    warning: {
      type: Boolean,
      default: false
    },
    success: {
      type: Boolean,
      default: false
    },
    icon: String,
    button: Object,
    value: [String, Number],
    max: Number,
    min: Number
  },
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    style() {
      return {
        "form-success": this.success,
        "form-warning": this.warning,
        "form-danger": !this.valid,
        "form-primary": this.valid
      };
    },
    btnStyle() {
      return {
        "border-btn-success": this.success,
        "border-btn-warning": this.warning,
        "border-btn-danger": !this.valid,
        "border-btn-primary": this.valid
      };
    }
  },
  methods: {
    verifyNumber(value) {
      if (parseInt(value) > this.max) {
        this._value = this.max;
      }
      if (parseInt(value) < this.min) {
        this._value = this.min;
      }
    }
  }
};
</script>
